
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import CrudLayout from "@/layouts/CrudLayout.vue";
import { Validate } from "vuelidate-property-decorators";
import {
  maxLength,
  maxValue,
  minLength,
  minValue,
  required
} from "vuelidate/lib/validators";

import { requiredIfValue } from "@/validators/validators";
import Validation from "@/components/Validation.vue";
import IStand from "@/models/IStand";
import Dropdown from "@/components/Dropdown.vue";
import i18n from "@/locales/index";
import FormBackButton from "@/components/buttons/FormBackButton.vue";
import AppConfig from "@/config/AppConfig";
import App from "@/App.vue";
import SortService from "@/service/SortService";

const namespace: string = "catalog";

@Component({
  components: {
    FormBackButton,
    Dropdown,
    CrudLayout,
    Validation
  }
})
export default class StandForm extends Vue {
  // component initial values
  public name: string = "StandForm";
  private resourceName = "stand";

  // props
  @Prop({ default: "add" })
  public type: any;
  @Prop({ default: false })
  public saved: any;
  @Prop({ default: false })
  public resetAction: any;
  @Prop({ default: false })
  public loading: any;
  @Prop({ default: "" })
  public error: any;
  @Prop({
    default: function() {}
  })
  public saveAction: any;
  @Prop({
    default: function() {}
  })
  public back: any;
  @Prop()
  public item: any;

  // model

  @Validate({ required, minLength: minLength(2), maxLength: maxLength(64) })
  public stand: string = "";

  @Validate({
    requireIfValue: requiredIfValue("catalogType", "2")
  })
  public deck: string | null = "";

  @Validate({
    requireIfValue: requiredIfValue("catalogType", "2")
  })
  public ship: string | null = "";

  @Validate({})
  public description: string = "";

  @Validate({
    required,
    minValue: minValue(1),
    maxValue: maxValue(2)
  })
  public catalogType: number | null = null;

  @Watch("item", { immediate: true, deep: true })
  protected onItemChange(item: any) {
    if (item) {
      this.init(item);
    }
  }

  private catalogTypes = AppConfig.CATALOG_TYPES;
  private ships = AppConfig.SHIPS;
  private decks = AppConfig.DECKS;

  private get getDecks(): any[] {
    return SortService.arrayAsc(this.decks, "name");
  }

  private get getShips(): any[] {
    return SortService.arrayAsc(this.ships, "name");
  }

  private get getCatalogTypes(): any[] {
    return SortService.arrayAsc(this.catalogTypes, "name");
  }

  private init(stand: IStand): void {
    this.$nextTick(() => {
      this.stand = stand.stand;
      this.catalogType = stand.catalog_type;

      if (stand.ship != "null") {
        this.ship = String(stand.ship);
      }
      if (stand.deck != "null") {
        this.deck = String(stand.deck);
      }

      this.description = stand.description;
      this.catalogType = stand.catalog_type;
    });
  }

  private selectCatalogType(value: any) {
    this.catalogType = value;
  }

  private selectDeck(value: any) {
    this.deck = value;
  }

  private selectShip(value: any) {
    this.ship = value;
  }

  private onCatalogTypeChanged(e: Event): void {
    this.$v.$touch();
  }

  public onSubmit(e: Object): void {
    if (this.catalogType == 1) {
      this.deck = null;
      this.ship = null;
    }
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }
    const stand: IStand = {
      stand: this.stand,
      catalog_type: this.catalogType as number,
      ship: this.ship,
      deck: this.deck,
      description: this.description,
      catalog_id: this.item?.catalog_id ?? -1
    };

    if (this.$route.params.id) {
      // edit
      stand.id = Number.parseInt(this.$route.params.id);
      this.saveAction({
        resource: this.resourceName,
        data: stand,
        id: stand.id,
        descriptionField: "stand"
      });
    } else {
      // add
      this.saveAction({
        resource: this.resourceName,
        data: stand,
        descriptionField: "stand"
      });
    }
  }
}
